import * as utils from './utils';
import {DELIVERY_EXTRA} from 'balaan_constants/goods';

export const formOptionsPreset = {
  EXISTS_YN: [
    {text: '전체', value: 'ALL'},
    {text: '있음', value: 'y', variant: 'success'},
    {text: '없음', value: 'n', variant: 'warning'}
  ],
  EXISTS_Y: [
    {text: '전체', value: 'ALL'},
    {text: '있음', value: 'y', variant: 'success'},
  ],
  YESNO_YN: [
    {text: '전체', value: 'ALL'},
    {text: 'Y', value: 'y', variant: 'success'},
    {text: 'N', value: 'n', variant: 'warning'}
  ],
  YESNO_Y: [
    {text: '전체', value: 'ALL'},
    {text: 'Y', value: 'y', variant: 'success'},
  ],
  USE_YN: [
    {text: '전체', value: 'ALL'},
    {text: '사용', value: 'y', variant: 'success'},
    {text: '사용안함', value: 'n', variant: 'warning'}
  ],
  USE_Y: [
    {text: '전체', value: 'ALL'},
    {text: '사용', value: 'y', variant: 'success'},
  ],
  AVAIL_YN: [
    {text: '전체', value: 'ALL'},
    {text: '가능', value: 'y', variant: 'success'},
    {text: '불가능', value: 'n', variant: 'warning'},
  ],
  AVAIL_Y: [
    {text: '전체', value: 'ALL'},
    {text: '가능', value: 'y', variant: 'success'},
  ],
  SORT_DIR: [
    {text: '높은순', value: 'desc', variant: 'primary'},
    {text: '낮은순', value: 'asc', variant: 'success'},
  ]
};

/**
 * front 용 constants 를 모아둔다. 아래와 같은 이유이다.
 * - mixin 의 data 에서 공통 constants 를 모아두려 했으나 각 component 의 data 에서 해당 값을 읽을 수 없다.
 * - balaan_constants 에 일일히 적용하기에는 개발 및 배포가 복잡해진다.
 * - front 전용이라면 여기에 두고 사용하면 되며, 백엔드까지 공통으로 사용해야 할 때는 balaan_constants 로 이동시킨다.
 */
const CONSTANTS = {
  GM_STATUS: [
    {text: '매칭 대상', value: 'not_matched'},
    {text: '임시 매칭', value: 'temp_matched'},
    {text: '확정 매칭', value: 'confirmed'},
    {text: '[보류]5분 초과', value: 'excl_five_minutes'},
    {text: '[보류]모듬상품', value: 'excl_multioption'},
    {text: '[보류]단일 파트너', value: 'excl_single_partner'},
    {text: '[보류]브랜드 오류', value: 'excl_wrong_brand'},
    {text: '[보류]기타', value: 'excl_etc'}
  ],
  DELIVERY_EXTRA_FILTER: [
    {text: '전체', value: 'ALL'},
    {text: '일반 택배배송', value: null},
    ...DELIVERY_EXTRA
  ],
  formOptionsPreset
};
// CONSTANTS 를 이용한 추가 작업
CONSTANTS.GM_STATUS_MAP = utils.arr2map(CONSTANTS.GM_STATUS, 'value', 'text');



export default CONSTANTS;
